import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Button, Modal } from "semantic-ui-react";

const SocialMediaSidebar = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => setModalOpen(false);
  const handleOpen = () => setModalOpen(true);

  return (
    <nav id="social-media-sidebar" className="social-media-nav">
      <ul>
        <li className="side-social facebook">
          <a
            href="https://www.facebook.com/share/z4YmJEkoe4hopvLC/?mibextid=qi2Omg"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon="dashicons:facebook-alt" color="white" />
          </a>
        </li>

        <li className="side-social wechat">
          <a
            href="https://wa.me/972528950200"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="bi:whatsapp" color="white" />
          </a>
        </li>
        <li className="side-social spotify">
          <a
            href="https://open.spotify.com/artist/6VmmG0GVz7Eaj3fnh2AD57?si=yYYLo15vT7KjSCJFaVl8xg"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon="akar-icons:spotify-fill" color="#72AB19" />
          </a>
        </li>
        <li className="side-social instagram">
          <a
            href="https://www.instagram.com/proffesor_kof_nadafni?igshid=bGE3M2E5OXloZ2Zh"
            target="_blank"
            rel="noreferrer"
          >
            <Icon icon="akar-icons:instagram-fill" color="white" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default SocialMediaSidebar;
