import React from "react";
import styled from "styled-components";
import { Container, Grid } from "semantic-ui-react";
import paper4 from "./paper4.jpeg";

// Updated video imports
import movieone from "../assets/video/movieone.mp4";
import movietwo from "../assets/video/movietwo.mp4";
import movie3 from "../assets/video/movie3.mp4";
import movie4 from "../assets/video/movie4.mp4";
import movie5 from "../assets/video/movie5.mp4";
import movie6 from "../assets/video/movie6.mp4";

const MainStyledDiv = styled.div`
  background-image: url(${paper4});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StyledVideo = styled.video`
  width: 100%;
`;

const StyledTitle = styled.h2`
  color: #72ab19; // Change this as needed for your design
  text-align: center;
  margin: 1rem 0;
`;
const MainStyle = styled.div`
  padding: 15rem 10rem 0 10rem;

  @media (max-width: 1224px) {
    padding: 20rem 5rem 0 5rem;
  }
  @media (max-width: 768px) {
  }
`;

const Video = () => {
  const videos = [
    { src: movieone, title: "הפקה מוזיקלית שנכנסה לתוכנית המפקדת" },
    { src: movietwo, title: "ראיון שלי בגי׳גה גו׳ס לכבוד איפי וקליפ" },
    { src: movie3, title: "גולי52 ג׳אז דורסני-כלים עיבודים והפקה" },
    { src: movie4, title: "האננסים-מי המלך -הפקה" },
    { src: movie5, title: "פרופסור קוף בהופעה מיקס ועריכה " },
    { src: movie6, title: "וידאו ארט AUX MODE" },
  ];

  return (
    <MainStyledDiv>
      <MainStyle>
        <Grid doubling stackable>
          {videos.map((video, index) => (
            <Grid.Column
              key={video.src} // Assuming each videoSrc is unique; otherwise, find a unique property
              mobile={16} // Consider using a larger size for mobile to improve visibility
              tablet={16}
              computer={8} // Adjust based on desired layout
            >
              <StyledTitle>{video.title}</StyledTitle>
              <StyledVideo controls>
                <source src={video.src} type="video/mp4" />
                Your browser does not support the video tag.
              </StyledVideo>
            </Grid.Column>
          ))}
        </Grid>
      </MainStyle>
    </MainStyledDiv>
  );
};

export default Video;
