import React, { useState } from "react";
import styled from "styled-components";

import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
  Container,
  GridColumn,
} from "semantic-ui-react";
import last from "./last.jpeg";
import Footer from "./Footer";
const MainConnected = styled.div`
  background-image: url(${last});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
const Main = styled.div`
  @media (max-width: 768px) {
  }
  @media (max-width: 440px) {
  }
`;
const StyledContainer = styled(Container)`
  border-radius: 0.5em;

  margin-bottom: 2em;
  width: 80% !important;

  // Adjust width and padding on smaller screens
  @media (max-width: 768px) {
    padding: 4em;
    width: 90% !important;
  }

  @media (max-width: 480px) {
    padding: 2em;
    width: 95% !important;
  }
  @media (max-width: 450px) {
    padding: 4em;
    width: 100% !important;
  }
`;
const StyledHeader = styled(Header)`
  color: #fff !important;
`;

const StyledForm = styled(Form)`
  label {
    color: #fff !important;
    font-style: 300 !important;
    font-size: 1.5em !important;
  }

  input,
  textarea {
    background: #333 !important;
    border-color: #555 !important;
    color: #fff !important;
  }

  .button {
    background: #1b1c1d !important;
    color: #fff !important;
    &:hover {
      background: #575757 !important;
    }
  }
`;
const StyledParagraph = styled.p`
  color: #fff;
  font-size: 1.6em;
  line-height: 1.5; // Example style for better readability
  margin: 0.5em 0; // Example style for spacing
`;

const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #1e70bf; // Example hover color, change as you wish
  }
`;

interface ContactFormValues {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const Connect: React.FC = () => {
  const [formValues, setFormValues] = useState<ContactFormValues>({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleSubmit = () => {
    // TODO: Add submit logic
    console.log(formValues);
    alert("Form submitted, check the console for values.");
  };

  return (
    <MainConnected>
      <Main>
        <StyledContainer>
          <StyledForm inverted onSubmit={handleSubmit}>
            <Grid columns={2} stackable>
              <GridColumn>
                <StyledHeader as="h1" textAlign="right">
                  צור קשר
                </StyledHeader>
                <StyledParagraph>
                  אשמח לעמוד לרשותך בכל שאלה או בקשה. אשמח לשמוע ממך!
                </StyledParagraph>
                <StyledParagraph>
                  ניתן ליצור קשר באמצעות הטופס, או באמצעות המייל:
                </StyledParagraph>
                <StyledParagraph>
                  <StyledLink href="mailto:asds@dsd.com">
                    Impknd@gmail.com
                  </StyledLink>
                </StyledParagraph>
                <StyledParagraph>ניתן ליצור קשר גם בטלפון:</StyledParagraph>
                <StyledParagraph>
                  <StyledLink href="tel:1234567890">0528950200</StyledLink>
                </StyledParagraph>
              </GridColumn>
              <GridColumn>
                <Form.Input
                  fluid
                  label="שם:"
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                />
                <Form.Input
                  fluid
                  label="אימייל:"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                />
                <Form.Input
                  fluid
                  label="טלפון:"
                  name="phone"
                  value={formValues.phone}
                  onChange={handleChange}
                />
                <Form.TextArea
                  label="הודעה:"
                  name="message"
                  value={formValues.message}
                  onChange={handleChange}
                />
                <Button type="submit" fluid>
                  שלח
                </Button>
              </GridColumn>
            </Grid>
          </StyledForm>
        </StyledContainer>
        <Footer />
      </Main>
    </MainConnected>
  );
};

export default Connect;
