import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import logo from "./logo.png";

const StyledContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled(motion.img)`
  position: absolute;
  top: 40%;
  left: 50%;
  width: 414px; // Default size for mobile-first approach
  transform: translate(-50%, -50%);
  z-index: 2;

  // Responsive adjustments
  @media (min-width: 300px) {
    width: 360px;
    top: 35%;
  }
  @media (min-width: 400px) {
    width: 400px;
    top: 35%;
  }

  @media (min-width: 500px) {
    width: 414px;
    top: 40%;
  }

  @media (min-width: 768px) {
    width: 490px;
    top: 50%;
  }

  @media (min-width: 1024px) {
    width: 900px;
    top: 40%;
  }

  @media (min-width: 1600px) {
    width: 900px;
    top: 40%;
  }
`;

const CenteredButton = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100%);
  z-index: 2;
`;

const LogoText = styled(motion.div)`
  position: absolute;
  width: 100%;
  font-weight: 500;
  font-size: 28px; // Default font size for a mobile-first approach
  color: black;
  text-align: center;
  z-index: 2;
  top: 45%; // Default top for a mobile-first approach

  // Responsive adjustments
  @media (min-width: 390px) {
    font-size: 28px;
  }

  @media (min-width: 500px) {
    font-size: 30px;
    top: 45%;
  }

  @media (min-width: 600px) {
    font-size: 35px;
    top: 51%;
  }

  @media (min-width: 768px) {
    font-size: 30px;
    top: calc(50%);
  }

  @media (min-width: 1024px) {
    font-size: 40px;
    top: calc(50% + 10px);
  }
`;

const HomeMain = () => {
  return (
    <StyledContainer
      variants={{
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate="visible"
      transition={{ duration: 1.5 }}
    >
      <Logo src={logo} alt="Logo" />
      <LogoText>נגן, מפיק, עורך סאונד</LogoText>
      <CenteredButton>
        <button
          className="button-36"
          role="button"
          onClick={() =>
            window.open(
              "https://wa.me/972528950200",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          קבע/י פגישה
        </button>
      </CenteredButton>
    </StyledContainer>
  );
};

export default HomeMain;
