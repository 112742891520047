import React from "react";
import logo from "./logo.svg";
import "./App.css";

import "semantic-ui-css/semantic.min.css";
import HomeMain from "./components/HomeMain";
import styled from "styled-components";
import paper2 from "./paper2.jpeg";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import Connect from "./components/Connect";
import AudioPlayer from "./components/AudioPlayer";
import Video from "./components/Video";
import Footer from "./components/Footer";
import SocialMediaSidebar from "./components/SocialMediaSidebar";
import background from "./assets/background.jpeg";
// Styled components for the about section
const Main = styled.div`
  height: 100vh;
  width: 100%;
`;
const ContinerMain = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  width: 100%;
  height: 100%;
`;
const AboutSection = styled.section`
  text-align: right;
  background-image: url(${paper2});
  background-size: cover;
  height: 130vh;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
`;
const GradientBorder = styled.div`
  position: relative;
  z-index: 0;
  background: white; /* Assuming you want a white background */
  padding: 0px; /* Adjust the padding as needed */
  /* More styles here */

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    margin: -1px; /* A very thin border */
    background: linear-gradient(
      to right,
      #d7d7d7,
      #d3d3d3
    ); /* Gradient for the border */
    border-radius: 4px; /* Adjust the border-radius as needed */
    pointer-events: none; /* Ensure clicks pass through to the actual element */
  }
`;
const Title = styled.h1`
  color: #333;
  margin-bottom: 0.5em; // Adjust for spacing
  font-size: 4rem; // Large size for desktop
  text-align: center;
  margin-bottom: 0.1em; // Adjust for spacing
  @media (max-width: 1300px) {
    font-size: 3rem; // Large size for desktop
  }
  @media (max-width: 768px) {
    font-size: 3rem; // Slightly smaller for tablets
  }

  @media (max-width: 480px) {
    font-size: 2rem; // Even smaller for phones
  }
`;
const AboutUsTextBox = styled.div`
  border-radius: 8px;
  text-align: right;
  direction: rtl;

  height: 100%;
  margin: 0 auto;

  padding: 0rem 20rem 0 20rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  @media (max-width: 1300px) {
    padding: 16rem;
  }
  @media (max-width: 1150px) {
    padding: 13rem;
  }
  @media (max-width: 1000px) {
    padding: 8rem;
  }
  @media (max-width: 780px) {
    padding: 3rem;
  }
  @media (max-width: 600px) {
    padding: 0rem;
  }
`;

const MainParagraph = styled.p``;
const Paragraph = styled.p`
  color: #333;
  font-size: 2rem; // Default size
  @media (max-width: 1400px) {
    font-size: 1.5rem; // Adjust for readability on smaller screens
  }
  @media (max-width: 768px) {
    font-size: 1.4rem; // Adjust for readability on smaller screens
  }

  @media (max-width: 480px) {
    font-size: 1.2rem; // Small text for very small devices
  }
`;

function App() {
  return (
    <Main dir="rtl">
      <SocialMediaSidebar />
      <ContinerMain>
        <HomeMain />{" "}
      </ContinerMain>
      <AboutSection>
        <AboutUsTextBox>
          <Title>קצת עלי</Title>
          <Container>
            <MainParagraph>
              <Paragraph>
                אני נדב פרופסור קוף דפני מוזיקאי, מפיק, טכנאי הקלטות ועורך
                סאונד. הקצב תמיד הניע אותי והשפיע על הבחירות שלי, הרצון תמיד היה
                ויהיה ליצור בעברית את מה שחסר לי בנוף המקומי ופשוט להשתעשע,
                לעשות רעש, כיף ודברים יפים. אני רוצה לעבוד איתכם.
              </Paragraph>
              <Paragraph>
                יש לי למעלה מעשור ניסיון בהפיכת רעיונות לשירים, עיבוד והפקה. אתם
                תביאו את החזון שלכם, בין אם זה מנגינות ומילים, רעיונות שלכם,
                ויחד נבנה אותם מהסקיצה ועד לגרסאת מאסטר.
              </Paragraph>
              <Paragraph>בואו נעשה ביחד מוזיקה!</Paragraph>
            </MainParagraph>
          </Container>
        </AboutUsTextBox>
      </AboutSection>
      <div>
        <AudioPlayer />
      </div>
      <Video />
      <Connect />
      {/*
       */}
      {/*
  

    */}
    </Main>
  );
}

export default App;
