import React, { useState, useEffect, useRef } from "react";
import { Howl } from "howler";
import styled from "styled-components";
import back from "./back.jpg";
// Import the local MP3 files
import BEMA_ATA_BOHER_INSTRUMENTAL from "../assets/audio/BEMA_ATA_BOHER_INSTRUMENTAL.mp3";
import BLOODY_KIDS_INSTRU from "../assets/audio/BLOODY KIDS- INSTRU.mp3";
import Dance_music_2 from "../assets/audio/Dance music 2.mp3";
import LO_LAMESIBOT_INSTRUM from "../assets/audio/LO LAMESIBOT INSTRUM.mp3";
import LO_BARIE_INSTRU from "../assets/audio/LO_BARIE_INSTRU.mp3";
import NAVALIM_INSTRU from "../assets/audio/NAVALIM_INSTRU.mp3";
import Sad_parking_lot_music_2_fix from "../assets/audio/Sad parking lot music 2 fix.mp3";
import SHVIL_INSTRUMENTAL from "../assets/audio/SHVIL - INSTRUMENTAL.mp3";
import TEN_BISS_INSTRUM from "../assets/audio/TEN BISS INSTRUM.mp3";
import YAMIN_INSTRUMENTAL from "../assets/audio/YAMIN - INSTRUMENTAL.mp3";
import paper3 from "./paper3.jpeg";

import { Container, Grid, GridRow, GridColumn, Icon } from "semantic-ui-react"; // Import Icon component

const Main = styled.div`
  background-image: url(${paper3});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  width: 100%;
  height: 100vh;
  @media (max-width: 1300px) {
    height: 130vh;
  }
  @media (max-width: 768px) {
    height: 110vh;
  }
  @media (max-width: 480px) {
    height: 120vh;
  }
`;

const PlayerMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;

  @media (max-width: 1300px) {
    margin-top: 10%; /* Increased top margin */
    align-items: center; /* Ensure it stays centered */
    padding: 0 10px; /* Add some padding on the sides */
  }
`;

const StyledHeader = styled.div`
  width: 100%;
    z-index: 1;
  
  padding: 80px 0;
  text-align: center;
  font-size: 50px;
  font-weight: 400;
  color: white;
  @media (max-width: 768px) {
    font-size: 30px;
    padding: 40px 0;
    position: relative;
    }
    }

  @media (max-width: 480px) {
    font-size: 25px;
    padding: 20px 0;
  }
    
`;
const ServicesOffered = styled.div`
  padding: 1.5em; // Inner spacing
  background: rgba(255, 255, 255, 0.95); // Almost opaque white background
  border-radius: 15px; // Rounded corners
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Soft shadow for depth
  color: #333; // Text color for readability
  font-size: 1.6em; // Base font size
  line-height: 1.6; // Line height for better readability
  text-align: right; // Align text to the right for RTL
  direction: rtl; // Right-to-left direction for Hebrew text

  // Styling for the unordered list
  ul {
    list-style: none; // Remove default list styles
    padding: 0; // Reset padding
    margin: 0; // Reset margin

    // Styling for each list item
    li {
      margin-bottom: 0.5em; // Spacing between items
      position: relative;
      padding-right: 1.4em; // Space for custom bullet
      text-align: right; // Align text to the right for RTL

      // Custom bullet style
      &:before {
        content: "•"; // Bullet character
        position: absolute;
        right: 0; // Position to the right
        color: #72ab19; // Bullet color
        font-size: 1.2em; // Slightly larger bullet
      }
    }
  }
  @media (max-width: 1300px) {
    width: 80%; // Adjust width for larger screens
    margin: 30rem auto; // Center the container
  }

  // Responsive font sizes for different screen widths
  @media (max-width: 768px) {
    li {
      margin-bottom: 0.5em; // Spacing between items
      position: relative;
      padding-right: 1.4em; // Space for custom bullet
      text-align: right; // Align text to the right for RTL
      font-size: 0.8em; // Slightly smaller font size
    }
  }

  @media (max-width: 480px) {
    font-size: 0.8em; // Even smaller font size for phones
    padding: 1em; // Less padding on smaller screens
    width: 100%; // Adjust width for smaller screens
    p {
      font-size: 1.9em;
    }
    li {
      margin-bottom: 0.5em; // Spacing between items
      position: relative;
      padding-right: 1.4em; // Space for custom bullet
      text-align: right; // Align text to the right for RTL
      font-size: 1.4em; // Slightly smaller font size
    }
  }
`;
const AudioPlayer: React.FC = () => {
  const [playing, setPlaying] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [progress, setProgress] = useState(0); // Add progress state
  const playerRef = useRef<Howl | null>(null);
  const progressIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const tracksTranslations: Record<
    string,
    { english: string; hebrew: string }
  > = {
    BEMA_ATA_BOHER_INSTRUMENTAL: {
      english: "BEMA ATA BOHER INSTRUMENTAL",
      hebrew: "בימה אתה בוחר (אינסטרומנטל)",
    },
    BLOODY_KIDS_INSTRU: {
      english: "Bloody Kids - Instru",
      hebrew: "ילדים דמים - אינסטרו",
    },
    Dance_music_2: {
      english: "Dance Music 2",
      hebrew: "מוזיקת ריקודים 2",
    },
    LO_LAMESIBOT_INSTRUM: {
      english: "LO LAMESIBOT INSTRUM",
      hebrew: "לא למסיבות - אינסטרומנטל",
    },
    LO_BARIE_INSTRU: {
      english: "LO BARIE INSTRU",
      hebrew: "ביט היפ הופ מגניב",
    },
    NAVALIM_INSTRU: {
      english: "NAVALIM INSTRU",
      hebrew: "ביט היפ הופ מרושע",
    },
    Sad_parking_lot_music_2_fix: {
      english: "Sad Parking Lot Music 2 Fix",
      hebrew: "מוזיקה עצובה של תהיות",
    },
    SHVIL_INSTRUMENTAL: {
      english: "SHVIL INSTRUMENTAL",
      hebrew: "מסע לילה",
    },
    TEN_BISS_INSTRUM: {
      english: "TEN BISS INSTRUM",
      hebrew: "תןביס- אחנסטרומנטלי",
    },
    YAMIN_INSTRUMENTAL: {
      english: "YAMIN INSTRUMENTAL",
      hebrew: "הדרך הביתה",
    },
  };

  const tracks = [
    {
      src: BEMA_ATA_BOHER_INSTRUMENTAL,
      translations: {
        english: "BEMA ATA BOHER INSTRUMENTAL",
        hebrew: "במה אתה בוחר ",
      },
    },
    {
      src: BLOODY_KIDS_INSTRU,
      translations: {
        english: "Bloody Kids - Instru",
        hebrew: "ילדים דמיים",
      },
    },
    {
      src: Dance_music_2,
      translations: {
        english: "Dance Music 2",
        hebrew: "מוזיקת ריקודים 2",
      },
    },
    {
      src: LO_LAMESIBOT_INSTRUM,
      translations: {
        english: "LO LAMESIBOT INSTRUM",
        hebrew: "לא למסיבות",
      },
    },
    {
      src: LO_BARIE_INSTRU,
      translations: {
        english: "LO BARIE INSTRU",
        hebrew: "ביט היפ הופ מגניב",
      },
    },
    {
      src: NAVALIM_INSTRU,
      translations: {
        english: "NAVALIM INSTRU",
        hebrew: "ביט היפ הופ מרושע",
      },
    },
    {
      src: Sad_parking_lot_music_2_fix,
      translations: {
        english: "Sad Parking Lot Music 2 Fix",
        hebrew: "מוזיקה עצובה של תהיות",
      },
    },
    {
      src: SHVIL_INSTRUMENTAL,
      translations: {
        english: "SHVIL INSTRUMENTAL",
        hebrew: "פאנק שמח",
      },
    },
    {
      src: TEN_BISS_INSTRUM,
      translations: {
        english: "TEN BISS INSTRUM",
        hebrew: "ביט מגניב",
      },
    },
    {
      src: YAMIN_INSTRUMENTAL,
      translations: {
        english: "YAMIN INSTRUMENTAL",
        hebrew: "פאנק פיצוצים",
      },
    },
  ];

  const clearProgressInterval = () => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
      progressIntervalRef.current = null;
    }
  };

  const startProgressInterval = () => {
    clearProgressInterval(); // Clear existing interval
    progressIntervalRef.current = setInterval(() => {
      if (playerRef.current) {
        const currentProgress =
          (playerRef.current.seek() / playerRef.current.duration()) * 100;
        setProgress(currentProgress);
      }
    }, 1000); // Update progress every second
  };

  useEffect(() => {
    playerRef.current = new Howl({
      src: [tracks[currentTrackIndex].src],
      html5: true,
    });

    playerRef.current.on("play", () => setPlaying(true));
    playerRef.current.on("pause", () => setPlaying(false));
    playerRef.current.on("end", () => {
      setCurrentTrackIndex((prevIndex) => (prevIndex + 1) % tracks.length);
    });

    // Automatically play when track changes
    if (playing) {
      playerRef.current.play();
    }

    return () => {
      playerRef.current?.stop();
      playerRef.current = null;
    };
  }, [currentTrackIndex, playing]);

  const togglePlayback = () => {
    if (playerRef.current) {
      if (playing) {
        playerRef.current.pause();
      } else {
        playerRef.current.play();
      }
    }
  };

  const playNextTrack = () => {
    setCurrentTrackIndex((prevIndex) => (prevIndex + 1) % tracks.length);
  };

  const playPreviousTrack = () => {
    setCurrentTrackIndex((prevIndex) =>
      prevIndex === 0 ? tracks.length - 1 : prevIndex - 1
    );
  };

  return (
    <Main>
      <StyledHeader>ההפקות שלי</StyledHeader>

      <Grid centered container>
        <GridColumn
          mobile={16}
          tablet={10}
          computer={10}
          largeScreen={8}
          widescreen={8}
        >
          <PlayerMain>
            <div
              className={`music-player-container ${
                playing ? "is-playing" : ""
              }`}
            >
              <div className="music-player">
                <div className="player-content-container">
                  <h1 className="artist-name">פרופסור קוף</h1>

                  <h3 className="song-title">
                    {tracks[currentTrackIndex].translations.hebrew || "Unknown"}
                  </h3>
                  <div className="music-player-controls">
                    <span
                      onClick={playPreviousTrack}
                      className="control-forwards"
                    ></span>
                    <div
                      className="control-play"
                      onClick={togglePlayback}
                    ></div>
                    <span
                      className="control-back"
                      onClick={playNextTrack}
                    ></span>
                  </div>
                </div>
              </div>

              <div className="album">
                <div className="album-art"></div>
                <div className="vinyl"></div>
              </div>
            </div>
          </PlayerMain>
        </GridColumn>
        <GridColumn
          mobile={16}
          tablet={16}
          computer={15}
          largeScreen={12}
          widescreen={8}
        >
          <ServicesOffered>
            <p>נותן שירות של:</p>
            <ul>
              <li>הפקת ביט (היפ הופ, אלקטרוני, כל מיני)</li>
              <li>הקלטות הפקה מוזיקלית (עיבוד, הלחנה, כתיבה)</li>
              <li>אבלטון להופעות חיות</li>
              <li>שיעורי הפקה באבלטון</li>
              <li>מיקסינג</li>
              <li>נגינה - הקלטות או לייב (בס, גיטרה, תופים, קלידים, סינתים)</li>
            </ul>
          </ServicesOffered>
        </GridColumn>
      </Grid>
    </Main>
  );
};

export default AudioPlayer;
